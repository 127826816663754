<template>
  <div class="home">
    <!--    菜单栏下面的轮播图-->
    <el-carousel :interval="5000" arrow="never" autoplay>
      <el-carousel-item v-for="(item, index) in banner" :key="index">
        <el-image
          :src="item.coverImgUrl"
          fit="cover"
          @click="toDetails(item.id)"
          style="cursor: pointer;"
        >
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </el-carousel-item>
    </el-carousel>
    <!--    产品模块-->
    <el-row>
      <div class="main-width-80">
        <h1>Powering Businesses Across Borders</h1>
        <ul class="row-card-list">
          <li
            v-for="(item, index) in cardList"
            :key="index"
            style="cursor: pointer;"
            @mouseenter="mouseOverCard(index)"
            @click="toDetails(item.id)"
          >
            <img :src="item.coverImgUrl" alt="" />
            <!--            <div class="product-img">-->
            <!--          -->
            <!--            </div>-->

            <h2>{{ item.title }}</h2>
            <p>
              {{ item.summary }}
            </p>
          </li>
        </ul>
      </div>
    </el-row>
    <!--  app下载模块  -->
    <el-row>
      <div class="main-width-80">
        <el-row>
          <el-col :span="12" class="col-border-right">
            <h3>Exclusive Perks & Offers</h3>
            <el-carousel
              height="250"
              :interval="2000"
              arrow="never"
              autoplay
              indicator-position="outside"
            >
              <el-carousel-item v-for="(item, index) in offerLis" :key="index">
                <div class="carousel-item">
                  <img :src="item.coverImgUrl" alt="" style="cursor: pointer;" @click="toDetails(item.id)"/>
                  <div class="carousel-item-content">
                    <h5 style="text-align: left">
                      <router-link
                        :to="`/page/details?pageId=${item.id}`"
                        class="h5-link"
                      >
                        {{ item.title }}<i class="h5-link"></i>
                      </router-link>
                    </h5>
                    <p style="text-align: left">{{ item.summary }}</p>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :span="12" class="col-right-apps">
            <h3>Our Mobile Apps</h3>
            <div class="content">
              <div class="content-img">
                <el-image
                  src="https://aa-r0405-com.oss-cn-beijing.aliyuncs.com/SP-App%202.0-i12-n20u-fixed_new.png"
                  fit="scale-down;"
                ></el-image>
                <!--                <img src="https://www.singpost.com/sites/default/files/2021-08/SP-App%202.0-i12-n20u-fixed_new.png" alt="">-->
              </div>
              <div class="app-desc">
                <h4>LOG TOP</h4>
                <p>
                  Convenience at your fingertips. Download to keep track of
                  parcels, find our locations and more!
                </p>

                <div class="app-down">
                  <span>
                    <img
                      src="https://aa-r0405-com.oss-cn-beijing.aliyuncs.com/app-store.png"
                      alt=""
                    />
                  </span>
                  <span>
                    <img
                      src="https://aa-r0405-com.oss-cn-beijing.aliyuncs.com/google-play.png"
                      alt=""
                    />
                  </span>
                </div>
                <div class="app-button">
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <span class="app-col-button-email">Email Address</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="app-col-button-link"
                        >Send Download Link</span
                      >
                    </el-col>
                  </el-row>
                </div>
                <p class="apps-desc-p">
                  By submitting my email, I acknowledge and accept the
                  <a v-if="privacyPolicyPageUrl" :href="privacyPolicyPageUrl"
                    >Privacy Policy</a
                  >
                  of LOG TOP Group.
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-row>
    <!--    -->
    <el-row>
      <div class="main-width-80">
        <h1 class="featured-news-h1">Featured News</h1>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="featured-news-img" style="cursor: pointer;" @click="toDetails(news1.id)">
              <img :src="news1.coverImgUrl" alt="" />
            </div>
             <p class="featured-news-text">
             <span>{{ news1.time }}</span>
             <router-link :to="`/page/details?pageId=${news1.id}`"
               >{{news1.title}}</router-link
             >
           </p>
          </el-col>
          <el-col :span="12">
            <ul class="featured-news-list">
              <li>
                <img  style="cursor: pointer;" @click="toDetails(news2.id)" :src="news2.coverImgUrl" alt="" />
              </li>
              <li>
                <h6>{{ news2.time }}</h6>
                <p>
                  <router-link :to="`/page/details?pageId=${news2.id}`">{{ news2.title }}</router-link>
                </p>
              </li>
              <li>
                <h6>{{ news3.time }}</h6>
                <p>
                  <router-link :to="`/page/details?pageId=${news3.id}`">{{ news3.title }}</router-link>
                </p>
              </li>
              <li>
                <h6>{{ news4.time }}</h6>
                <p>
                  <router-link :to="`/page/details?pageId=${news4.id}`">{{ news4.title }}</router-link>
                </p>
              </li>
            </ul>
          </el-col>
        </el-row>
      </div>
    </el-row>
  </div>
</template>

<script>
import {
  productList,
  banner,
  websiteOffer,
  expressNew,
  websitePage
} from "../../src/api/index.js";
export default {
  name: "Home",
  data() {
    return {
      src: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      activeUrl: "",
      activeIndex: null,
      cardList: [],
      banner: [],
      offerLis: [],
      news1: {},
      news2: {},
      news3: {},
      news4: {},
      privacyPolicyPageUrl: "",
    };
  },
  created() {
    this.productListData();
    this.bannerList();
    this.websiteOfferList();
    this.expressNew();
  },
  mounted() {
    this.privacyPolicyPageUrl = localStorage.getItem("privacyPolicyPageUrl");
  },
  methods: {
    mouseOverCard(index) {
      this.activeIndex = index;
    },
    productListData() {
      productList().then((res) => {
        this.cardList = res.data;
      });
    },
    bannerList() {
      banner().then((res) => {
        this.banner = res.data;
      });
    },
    websiteOfferList() {
      websiteOffer().then((res) => {
        this.offerLis = res.data;
      });
    },
    toDetails(id) {
      this.$router.push({
        path: "/page/details",
        query: {
          pageId: id,
        },
      });
    },
    expressNew() {
      expressNew().then((res) => {
        this.news1 = res.data[0];
        this.news2 = res.data[1];
        this.news3 = res.data[2];
        this.news4 = res.data[3];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  margin-top: 10px;
  /deep/ .el-carousel__button {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    opacity: 1;
    
  }
  /deep/ .el-carousel__container {
    height: 400px;
  }
  /deep/ .is-active button {
    background: #dc3545;
  }
  .main-width-80 {
    width: 80%;
    margin-left: 10% !important;
    margin-right: 10% !important;
    padding-top: 15px;
    /deep/ .el-carousel__indicators--outside .is-active .el-carousel__button {
      background: #dc3545;
    }
    h1 {
      font-size: 30px;
      color: #000;
      font-weight: 600;
      padding-bottom: 30px;
      padding-top: 20px;
    }
    h3 {
      text-align: left;
      font-size: 20px;
      color: #666;
      margin-bottom: 10px;
    }
    .row-card-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      li {
        width: 25%;
        margin-bottom: 20px;
        //.product-img{
        //  width:110px;
        //  height:110px;
        //  border-radius: 50%;
        //  transition:all 1s ease-out;
        //  img{
        //    width:100%;
        //    height:100%;
        //  }
        //  img:hover {
        //    transform:scale(1);
        //  }
        //}
        img {
          &:hover {
            transform: scale(1.1);
          }
        }
        h2 {
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 22px;
        }
        p {
          text-align: center;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .carousel-item {
      display: flex;
      flex-direction: row;
      img {
        width: 63%;
      }
      .carousel-item-content {
        margin-left: 20px;
        margin-right: 20px;
        a {
          color: #007bff;
          font-size: 16px;
          font-weight: 800;
          text-align: center;
          &:hover {
            color: #2f5179;
          }
          .h5-link {
            display: inline-block;
            width: 10px;
            height: 10px;
            background: url("/assets/img/share.png") no-repeat;
            background-size: cover;
          }
        }
        p {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
        }
      }
    }
    .col-border-right {
      position: relative !important;
    }

    .col-right-apps {
      padding-left: 20px;
      border-left: 1px solid #999999;
      .content {
        display: flex;
        flex-direction: row;
        .content-img {
          width: 40%;
          margin-right: 1%;
          img {
            width: 160px;
            height: 160px;
          }
        }

        .app-desc {
          width: 59%;
        }
        h4 {
          text-align: left;
          font-size: 18px;
          font-weight: 600;
          padding-bottom: 10px;
        }
        p {
          font-size: 16px;
          text-align: left;
          font-weight: 500;
        }
        .app-down {
          text-align: left;
          margin-top: 20px;
          margin-bottom: 40px;
          span {
            display: inline-block;
            width: 100px;
            height: 40px;
            &:nth-child(1) {
              margin-right: 10px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .app-button {
          width: 100%;
          text-align: left;
          span {
            display: inline-block;
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 13px;
            font-weight: 500;
            border-radius: 5px;
          }
          .app-col-button-email {
            background: #f2f2f2;
            color: #333;
          }
          .app-col-button-link {
            background: #3982ea;
            color: #fff;
            cursor: pointer;
          }
        }
        .apps-desc-p {
          text-align: left;
          padding-top: 15px;
          color: #333;
          font-size: 15px;
          a {
            color: #007bff;
          }
        }
      }

      .app-button {
        span {
          &:nth-child(2) {
            background: #007bff;
          }
        }
      }
      .apps-desc-p {
        i {
          color: #007bff;
        }
      }
    }
    /deep/ .el-carousel {
      position: static;
    }
    /deep/ .el-carousel__indicators--outside {
      position: absolute;
      top: 0;
      right: 20px;
      z-index: 1000;
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .el-carousel__button {
        background: #fff;
        border: 1px solid #cdcdcd;
        width: 10px;
        height: 10px;
      }
    }
    /deep/ .el-carousel__container {
      height: 250px !important;
    }
    //Featured News 特色新闻
    .featured-news-h1 {
      text-align: left;
      color: #666;
      font-size: 24px;
      font-weight: 500;
      padding-bottom: 15px;
    }
    .featured-news-img {
      width: 100%;
      height: 250px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .featured-news-text {
      span,
      a {
        display: block;
        text-align: left;
      }
      span {
        color: #666;
        font-size: 9px;
        font-weight: 700;
        margin-bottom: 10px;
        text-transform: uppercase;
        padding-top:10px;
      }
      a {
        color: #3982ea;
        font-size: 16px;
        font-weight: bold;
        font-family: roboto, sans-serif;
        &:hover {
          color: #2f5179;
        }
      }
    }
    .featured-news-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        width: 50%;
        text-align: left;
        margin-bottom: 20px;
        padding-right: 30px;
        cursor:pointer;
        img {
          width: 100%;
          height: 110px;
        }
        h6 {
          font-size: 13px;
          color: #666;
          padding-bottom: 10px;
        }
        p {
          a {
            color: #3982ea;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            font-family: roboto, sans-serif;
            &:hover {
              color: #2f5179;
            }
            .h5-link {
              display: inline-block;
              width: 10px;
              height: 10px;
              background: url("/assets/img/share.png") no-repeat;
              background-size: cover;
            }
          }
        }
      }
      .news-margin-top {
        padding-top: 20px;
      }
    }
  }
}
</style>
